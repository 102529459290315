
.user-con {
  margin-right: 0.4rem;
}

.icon-user {
  width: 0.24rem;
  height: 0.26rem;
  object-fit: contain;
  cursor: pointer;
}

.content {
  padding: 0.22rem 0.16rem;

  p {
    color: #bdbdbd;
    font-family: Poppins;
    font-size: 0.12rem;
    line-height: 1.2em;
    font-style: normal;
    font-weight: 400;
  }

  .list {
    display: flex;
    flex-direction: column;

    .item {
      margin-top: 0.08rem;
      display: flex;
      align-items: center;
      padding: 0.08rem;
      box-sizing: border-box;
      border-radius: 0.04rem;
      cursor: pointer;
      transition: all 0.3s linear;

      &:hover {
        background: #f1f1f1;

        .default {
          display: none;
        }

        .on {
          display: block;
        }

        span {
          color: #8644ff;
        }
      }

      .default {
        display: block;
      }

      .on {
        display: none;
      }

      img {
        margin-right: 0.04rem;
        width: 0.2rem;
        height: 0.2rem;
        object-fit: contain;
      }

      span {
        color: #000;
        font-family: Poppins;
        font-size: 0.14rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4em;
        transition: all 0.3s linear;
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .user-con {
    margin-right: 0.14rem;
  }

  .icon-user {
    width: 0.18rem;
    height: 0.18rem;
  }
}
