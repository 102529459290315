
.page-nav {
    padding-top: 0.4rem;
    padding-bottom: 0.2rem;
    display: flex;
    cursor: pointer;


    img {
        flex: 0 0 auto;
        margin-top: 0.08rem;
        margin-right: 0.2rem;
        width: 0.32rem;
        height: 0.32rem;
        object-fit: contain;

    }

    h2 {
        flex: 1;
        color: #382187;
        font-family: Poppins;
        font-size: 0.36rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;
    }
}

@media screen and (max-width: 768px) {
    .page-nav {
        padding-top: 0.24rem;
        padding-bottom: 0.16rem;

        img {
            margin-right: 0.08rem;
            margin-top: 0.02rem;
            width: 0.2rem;
            height: 0.2rem;
        }

        h2 {
            font-size: 0.2rem;
            line-height: 0.24rem;
        }


    }
}
