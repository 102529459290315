
.icon-lang {
    width: 0.24rem;
    height: 0.26rem;
    object-fit: contain;
    cursor: pointer;
}

.content {
    padding: 0.22rem 0.16rem;

    p {
        color: #BDBDBD;
        font-family: Poppins;
        font-size: 0.12rem;
        line-height: 1.2em;
        font-style: normal;
        font-weight: 400;
    }

    .list {
        display: flex;
        flex-direction: column;

        .item {
            min-width: 1.28rem;
            margin-top: 0.08rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.08rem;
            box-sizing: border-box;
            border-radius: 0.04rem;
            cursor: pointer;
            transition: all 0.3s linear;

            &.active,
            &:hover {
                background: #F1F1F1;

                span {
                    color: #8644FF;
                }

                .icon {
                    display: block;
                }
            }




            span {
                color: #000;
                font-family: Poppins;
                font-size: 0.14rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.4em;
                transition: all 0.3s linear;
            }

            .icon {
                margin-left: 0.08rem;
                display: none;
                color: #8644FF;
            }
        }
    }
}

@media screen and (max-width: 768px) {


    .icon-lang {
        width: 0.18rem;
        height: 0.18rem;
    }
}
