
.copy-btn {
    display: inline-block;
    flex: 0 0 auto;
    width: 0.22rem;
    height: 0.22rem;
    background: url('../../public/img/icon_copy.svg') no-repeat center;
    background-size: contain;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .copy-btn {
        width: 0.16rem;
        height: 0.16rem;
    }
}
