html,
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
form,
input,
textarea,
th,
td,
select {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Source Han Sans CN';
  font-style: normal;
  word-break: break-word;
  border: 0;
  background: none;
  line-height: 1;
}

html,
body {
  min-height: 100%;
}

body {
  font-size: 14px;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul,
ol {
  list-style: none;
}

img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
  border: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #232323;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

input,
textarea {
  outline: none;
  border: none;
}

textarea {
  resize: none;
  overflow: auto;
}

.clearfix {
  zoom: 1;
}

.overflow1 {
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box;			//特别显示模式 */
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.overflow2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.overflow3 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.clearfix:after {
  content: '.';
  width: 0;
  height: 0;
  visibility: hidden;
  display: block;
  clear: both;
  overflow: hidden;
}

.ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline {
  display: inline-block;
  display: inline;
  zoom: 1;
}

.flex {
  display: flex;
}

.flexcenter {
  display: -webkit-flex;
  justify-content: center;
  /*水平居中*/
  align-items: center;
  /*垂直居中*/
  -webkit-align-items: center;
}

/*  */
.van-toast {
  padding: .14rem .30rem;
}

.van-toast__text {
  font-size: 0.24rem;
}

@media screen and (max-width: 1000px) {
  .van-toast {
    font-size: .16rem !important;
    padding: .12rem .16rem;
    z-index: 99999 !important;
  }

  .van-toast__text {
    font-size: .18rem !important;
  }
}

.el-button.is-loading:before {
  display: none;
}

/* 下拉框样式 全局 */
.el-dropdown__popper.el-popper[role='tooltip'] {
  background: transparent;
  border: 0;
}

.el-dropdown-menu {
  background: #eaeaea;
  border-radius: .10rem;
  backdrop-filter: blur(.23rem);
  overflow: hidden;
}

.el-dropdown-menu__item {
  font-size: .20rem;
  padding: 0 .20rem;
  font-family: 'Source Han Sans CN';
  font-weight: 400;
  color: #000;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background: #3081d0;
  color: #fff;
}

.el-dropdown__popper.el-popper[role='tooltip'][data-popper-placement^='top'] .el-popper__arrow::before,
.el-dropdown__popper.el-popper[role='tooltip'][data-popper-placement^='left'] .el-popper__arrow::before,
.el-dropdown__popper.el-popper[role='tooltip'][data-popper-placement^='right'] .el-popper__arrow::before,
.el-dropdown__popper.el-popper[role='tooltip'][data-popper-placement^='bottom'] .el-popper__arrow::before {
  background: #eaeaea;
  border-color: #eaeaea;
}

/* 弹窗背景透明 */
.popup-transparent {
  background-color: transparent;
}

.el-loading-mask {
  background-color: rgba(0, 0, 0, 0.5);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.00rem;
}

.pagination .el-pager li.active {
  background: linear-gradient(270deg, #8453ea, #3461ff);
}

.pagination .el-pager li {
  background: rgba(16, 20, 49, 0.45);
  color: #fff;
}

.el-pagination .btn-next,
.el-pagination .btn-prev {
  background: rgba(16, 20, 49, 0.45) !important;
  color: #fff !important;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* 滚动槽 */
/* ::-webkit-scrollbar-track { */
/* background: linear-gradient(270deg, #8453ea, #3461ff); */
/* } */
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.26);
  /* background: rgba(0, 0, 0, 0.1); */
  /* background: linear-gradient(270deg, #8453ea, #3461ff); */
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.26);
}



.com-container {
  width: 1470px;
  padding-left: 0.15rem;
  padding-right: 0.15rem;
  box-sizing: border-box;
  margin: 0 auto;
}

html {
  font-size: 100px;
}

@media screen and (max-width: 1470px) {
  .com-container {
    width: 1200px;
  }
}

@media screen and (max-width: 1200px) {
  /* html {
    font-size: 90px;
  } */

  .com-container {
    width: 970px;
  }
}

@media screen and (max-width: 992px) {
  .com-container {
    width: 750px;
  }
}

@media screen and (max-width: 768px) {
  /* html {
    font-size: 85px;
  } */

  .com-container {
    width: 100%;
    padding: 0 0.2rem;
  }
}

.popup-transparent {
  background: transparent !important;
}

.van-popover[data-popper-placement=bottom-end] .van-popover__arrow {
  right: 0.02rem;
}