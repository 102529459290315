
.page-header {
    width: 100%;
    height: 1.88rem;
    background: linear-gradient(45deg, #160D51 0%, #5D1C9E 100%);
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../../public/img/header_decorate.svg') no-repeat center;
        background-size: cover;

    }



    .header-con {
        padding: 0.2rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
            img {
                height: 0.82rem;
                cursor: pointer;
                position: relative;
                z-index: 1;
            }
        }

        .right {
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
        }
    }
}

@media screen and (max-width: 768px) {
    .page-header {
        height: 1.1rem;

        .header-con {
            padding: 0.12rem 0;

            .left {
                img {
                    height: 0.32rem;
                }
            }
        }
    }
}
