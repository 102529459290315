
.code-con {
    margin-top: 0.6rem;
    width: calc(100% + 0.3rem);
    margin-left: -0.15rem;
    display: flex;

    .inp-con {
        min-width: 0;
        flex: 1;
        padding: 0 0.15rem;
        box-sizing: border-box;

        .inp {
            width: 100%;
            height: 0.55rem;
            text-align: center;
            line-height: 0.55rem;
            border-bottom: 1px solid#EDEDED;
            margin-right: 0.1rem;
            color: #8644FF;
            font-size: 0.18rem;

            &.active {
                border-bottom: 1px solid #8644FF;
            }
        }
    }
}



@media screen and (max-width: 768px) {
    .code-con {
        width: calc(100% + 0.14rem);
        margin-left: -0.07rem;
        margin-top: 0.65rem;

        .inp-con {
            padding: 0 0.07rem;

            .inp {
                height: 0.42rem;
            }
        }

        .code-item {
            height: 0.42rem;
        }

        .code-item-con {}

        .input-code {
            height: 0.42rem;
        }
    }
}
