
.my-image {
    position: relative;

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

:deep {

    .van-image__error,
    .van-image__loading {
        background: #E0E0E0;

        img {
            width: 100%;
        }


    }
}
